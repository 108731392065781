<template>
  <div class="row">
    <div class="col-md-12">
      <datatable
        :stt="true"
        :title="'Danh sách Tool Facebook'"
        :columns="columns"
        :rows="toolFb"
        :defaultPerPage="10"
      >
        <th slot="thead-tr-o" class="text-center" style="vertical-align: middle">Thao Tác</th>
        <td slot="tbody-tr-o" class="text-center" slot-scope="props">
          <button
            v-if="props.row.status === 1"
            class="btn btn-danger font-10 py-1 btn-xs"
            @click="hideToolFb(props.row)"
          >
            Ẩn
          </button>
          <button
            v-if="props.row.status === 0"
            class="btn btn-danger font-10 py-1 btn-xs"
            @click="unHideToolFb(props.row)"
          >
            Mở
          </button>
        </td>
      </datatable>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import toolApi from "@/api/tool";
import Datatable from "@/components/datatable/Datatable.vue";

export default {
  name: "ToolFacebook",
  props: {
    tools: Array,
  },
  components: {
    Datatable,
  },
  watch: {
    tools() {
      this.toolFb = [...this.tools];
    },
  },
  data() {
    return {
      toolFb: [...this.tools],
      columns: [
        { label: "Name", field: "name" },
        { label: "Status", field: "status_name" },
        { label: "Package name", field: "package_name" },
        { label: "Tool", field: "tool" },
        { label: "Prices", field: "prices", numeric: true },
        { label: "Prices Id", field: "prices_id" },
        { label: "Id", field: "id" },
        { label: "Max", field: "max", numeric: true },
        { label: "Min", field: "min" },
      ],
    };
  },
  methods: {
    async hideToolFb(tool) {
      // const params = {
      //   id: tool.id,
      //   status: 0,
      // };
      // const response = await toolApi.update(params);
      // if (response?.success) {
      //   this.toolFb = this.toolFb.map((item) => {
      //     if (item.id !== tool.id) {
      //       return item;
      //     } else {
      //       item.status_name = "Đang ẩn";
      //       return item;
      //     }
      //   });
      // } else {
      //   this.$toastr.error(response?.message || "Thất bại");
      // }
    },
    async unHideToolFb(tool) {
      // const params = {
      //   id: tool.id,
      //   status: 1,
      // };
      // const response = await toolApi.update(params);
      // if (response?.success) {
      //   this.toolFb = this.toolFb.map((item) => {
      //     if (item.id !== tool.id) {
      //       return item;
      //     } else {
      //       item.status_name = "Đang mở";
      //       return item;
      //     }
      //   });
      // } else {
      //   this.$toastr.error(response?.message || "Thất bại");
      // }
    },
  },
};
</script>
