<template>
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 class="mb-sm-0 font-size-18">Quản lý Tool</h4>

            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="javascript: void(0);">Admin Traodoicheo</a>
                </li>
                <li class="breadcrumb-item active">Quản lý Tool</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <loading-vue v-if="loading" />
      <facebook-vue :tools="toolsFacebook" />
      <instagram-vue :tools="toolsInstagram" />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import toolApi from "@/api/tool";
import LoadingVue from "@/components/loading/Loading.vue";
import { buildParamQuery } from "@/helpers/index";
import FacebookVue from "./Facebook.vue";
import InstagramVue from "./Instagram.vue";

export default {
  name: "Tool",
  components: {
    LoadingVue,
    FacebookVue,
    InstagramVue,
  },
  data() {
    return {
      limit: 50,
      tools: [],
      loading: false,
    };
  },
  computed: {
    toolsFacebook() {
      const tool = this.tools.find((provider) => {
        return provider.id == 1;
      });
      if (tool) {
        return tool.prices.map((pk) => {
          pk.status_name = this.statusName(pk.status);
          return pk;
        });
      } else return [];
    },
    toolsInstagram() {
      const tool = this.tools.find((provider) => {
        return provider.id == 2;
      });
      if (tool) {
        return tool.prices.map((pk) => {
          pk.status_name = this.statusName(pk.status);
          return pk;
        });
      } else return [];
    },
  },
  created() {
    this.getLogsList();
  },
  methods: {
    async getLogsList() {
      const params = {
        limit: this.limit,
      };
      const queries = buildParamQuery(params);
      this.loading = true;
      const response = await toolApi.list(queries);
      this.loading = false;
      if (response.success) {
        this.tools = response.data;
      } else {
        this.$toastr.error(response.message);
      }
    },
    statusName(id) {
      switch (id) {
        case 1:
          return "Đang mở";
        case "1":
          return "Đang mở";
        case 0:
          return "Đang ẩn";
        case "0":
          return "Đang ẩn";
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
