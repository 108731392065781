<template>
  <div class="row">
    <div class="col-md-12">
      <datatable
        :stt="true"
        :title="'Danh sách Tool Instagram'"
        :columns="columns"
        :rows="toolsInsta"
        :defaultPerPage="10"
      >
      </datatable>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import toolApi from "@/api/tool";
import Datatable from "@/components/datatable/Datatable.vue";

export default {
  name: "ToolInstagram",
  components: {
    Datatable,
  },
  props: {
    tools: Array,
  },
  watch: {
    tools() {
      this.toolsInsta = [...this.tools];
    },
  },
  data() {
    return {
      toolsInsta: [],
      loading: false,
      columns: [
        { label: "Name", field: "name" },
        { label: "Status", field: "status_name" },
        { label: "Package name", field: "package_name" },
        { label: "Tool", field: "tool" },
        { label: "Prices", field: "prices", numeric: true },
        { label: "Prices Id", field: "prices_id" },
        { label: "Id", field: "id" },
        { label: "Max", field: "max", numeric: true },
        { label: "Min", field: "min" },
      ],
    };
  },
  created() {},
  methods: {
    async hideToolFb(tool) {
      const params = {
        id: tool.id,
        status: 0,
      };
      const response = await toolApi.list(params);
      if (response.success) {
        this.tools = response.data;
      } else {
        this.$toastr.error(response.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
